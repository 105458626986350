@import "~bootstrap/scss/bootstrap";

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
}
* + * {
  margin-top: 0.75rem;
}
body,
html {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 1.45;
  margin: 0;
}
html {
  padding: 0;
  font-size: 18px;
  scroll-behavior: smooth;
}
@media (min-width: 1000px) {
  html {
    font-size: 20px;
  }
}
body {
  color: var(--base-foreground);
  background: var(--base-hero-bg) !important;
  line-height: 1.5rem;
  max-width: 100vw;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text-heading);
}
h1 {
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 2.25rem;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}
h2 {
  font-size: 1.25rem;
}
h3,
h4,
h5,
h6 {
  font-size: 1.125rem;
}
strong {
  color: var(--color-text-emphasized);
  font-weight: 600;
}
li {
  margin-top: 0.5rem;
}
a {
  color: var(--color-link);
}

:focus {
  outline: 0 !important;
}

@-webkit-keyframes spin-hero-cycle {
  0% {
    transform: rotate(-1turn);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes spin-hero-cycle {
  0% {
    transform: rotate(-1turn);
  }
  to {
    transform: rotate(0);
  }
}
@font-face {
  font-family: "jwf";
  src: url(/assets/fonts/jwf-book.woff2) format("woff2"), url(/assets/fonts/jwf-book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "jwf";
  src: url(/assets/fonts/jwf-bookitalic.woff2) format("woff2"), url(/assets/fonts/jwf-bookitalic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "jwf";
  src: url(/assets/fonts/jwf-bold.woff2) format("woff2"), url(/assets/fonts/jwf-bold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "jwf";
  src: url(/assets/fonts/jwf-bolditalic.woff2) format("woff2"), url(/assets/fonts/jwf-bolditalic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "jwf";
  src: url(/assets/fonts/jwf-ultra.woff2) format("woff2"), url(/assets/fonts/jwf-ultra.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
:root {
  /** base colors **/
  --font-family: jwf, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --base-background: #2a9d8f;
  --base-foreground: white;
  --base-hero-text: #264653;
  --base-hero-bg: #e9c46a;
  --base-yellow-light: #e9c46a;
  --base-link: #e76f51;

  --base-selected-background: #2a9d8f;
  --base-section-odd: var(--base-background);
  --base-section-even: var(--base-hero-text);
  --base-section-border: var(--base-hero-bg);

  --base-card-title: var(--base-hero-text);
  --base-card-foreground: var(--base-hero-text);
  --base-card-bg: var(--base-hero-bg);
  --base-card-link: var(--base-link);

  --base-carousel-active: var(--base-hero-bg);

  --base-icons-icon: var(--base-foreground);
  --base-icons-bg: var(--base-background);
  --base-icons-icon-hover: var(--base-hero-text);
  --base-icons-bg-hover: var(--base-hero-bg);

  --base-bio-image-background: var(--base-hero-bg);
}

[data-theme="dark"] {
  /** base colors **/
  --base-background: #444;
  --base-foreground: #999;
  --base-hero-text: #333;
  --base-hero-bg: #e9c46a;
  --base-yellow-light: #e9c46a;
  --base-link: #e76f51;

  --base-selected-background: #555;
  --base-section-odd: var(--base-background);
  --base-section-even: var(--base-hero-text);
  --base-section-border: var(--base-hero-bg);

  --base-card-title: var(--base-hero-text);
  --base-card-foreground: var(--base-hero-text);
  --base-card-bg: var(--base-hero-bg);
  --base-card-link: var(--base-link);

  --base-carousel-active: var(--base-hero-bg);

  --base-icons-icon: var(--base-foreground);
  --base-icons-bg: var(--base-background);
  --base-icons-icon-hover: var(--base-hero-text);
  --base-icons-bg-hover: var(--base-hero-bg);

  --base-bio-image-background: var(--base-background);
}
