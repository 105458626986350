.App {
  background-color: var(--base-background);
  &__container {
    max-width: min(90vw, 950px);
    min-height: calc(100vh - 8rem - 5px);
  }
  &__container {
    margin-top: 5rem;
    max-width: 100vw;
  }

  &__theme img {
    width: 1.6rem;
  }

  &__select {
    border: 0;
    border-radius: 6em;
    height: 1.8em;
    padding-top: 2px;
    margin-left: 0.5rem;
    margin-top: 0.4em;
    font-size: 0.875rem;
    color: var(--base-foreground);
    background-color: var(--base-selected-background);
    &:focus {
      background-color: var(--base-background);
      border: none !important;
      outline: none !important;
    }
    &:hover {
      color: var(--base-hero-text) !important;
      background: var(--base-hero-bg) !important;
    }
  }

  button {
    border: 0;
    background: none !important;
  }
}

/** TRANSITIONS **/
.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
